<script>
    import { directive as onClickaway } from "vue-clickaway";

    export default {
        name: "ThePersonalSelect",
        props: {
            value: [String, Number],
            options: [Object, Array],
            type: String,
            disabled: Boolean,
            size: {
                type: String,
                default: "lg",
                validator: (v) => ["sm", "lg"].includes(v),
            },
        },
        data() {
            return {
                showDropdown: false,
                valueTitle: this.value,
            };
        },
        computed: {
            classNames() {
                return [
                    this.size ? `the-personal-select--${this.size}` : "",
                    this.disabled ? "the-personal-select--disabled" : "",
                ].filter(Boolean);
            },
        },
        methods: {
            toggleDropdown() {
                if (!this.disabled) {
                    this.showDropdown = !this.showDropdown;
                }
            },
            selectOption(option) {
                this.valueTitle = option.name;
                this.$emit("input", { option, type: this.type });
                this.hideDropdown();
            },
            hideDropdown() {
                setTimeout(() => {
                    this.showDropdown = false;
                }, 50);
            },
        },
        directives: {
            onClickaway,
        },
    };
</script>

<template>
    <div class="the-personal-select" :class="classNames">
        <div
            class="the-personal-select__name outline"
            :class="{ 'the-personal-select__name--active': showDropdown }"
            :tabindex="disabled ? -1 : 0"
            @click="toggleDropdown"
            @keypress.enter="toggleDropdown"
            v-on-clickaway="hideDropdown"
        >
            {{ valueTitle }}

            <svg
                v-if="!disabled"
                width="10"
                height="6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M.158.82A.783.783 0 011.263.66L5 3.5 8.737.66a.783.783 0 011.105.16.807.807 0 01-.158 1.12L5 5.5.316 1.94A.807.807 0 01.158.82z"
                    fill="#8F90A6"
                />
            </svg>
        </div>
        <div v-show="showDropdown" class="the-personal-select__dropdown">
            <div
                class="the-personal-select__item"
                :class="{ 'the-personal-select__item--selected': option.name === valueTitle }"
                v-for="(option, i) in options"
                :key="i"
                tabindex="0"
                @click="selectOption(option)"
                @keypress.enter="selectOption(option)"
            >
                {{ option.name }}
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-select {
        width: 100%;
        position: relative;
    }

    .the-personal-select--disabled {
        .the-personal-select__name {
            opacity: 0.7;
            cursor: default;
        }
    }

    .the-personal-select--sm {
        .the-personal-select__name {
            padding: 5px 30px 5px 10px;
            font-size: 14px;
            border-radius: $personal-select-radius-sm;
        }

        .the-personal-select__name--active {
            border-radius: $personal-select-radius-sm $personal-select-radius-sm 0 0;
        }

        .the-personal-select__dropdown {
            border-radius: 0 0 $personal-select-radius-sm $personal-select-radius-sm;
        }
    }

    .the-personal-select--lg {
        .the-personal-select__name {
            padding: 9px 23px 9px 15px;
            font-size: 16px;
            border-radius: $personal-input-radius;
            @include bp($bp-desktop-sm) {
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }

        .the-personal-select__name--active {
            border-radius: $personal-input-radius $personal-input-radius 0 0;
        }

        .the-personal-select__dropdown {
            border-radius: 0 0 $personal-input-radius $personal-input-radius;
        }
    }

    .the-personal-select__name {
        position: relative;
        color: #40404b;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: $personal-input-bg;
        border: $personal-border-width solid $personal-input-border-color;
        overflow: hidden;
        cursor: pointer;
        svg {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }
        @include bp($bp-desktop-sm) {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }

    .the-personal-select__name--active {
        svg {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .the-personal-select__dropdown {
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
        top: calc(100% - 1px);
        border: $personal-border-width solid $personal-input-border-color;
        border-top: 1px;
        overflow: auto;
        max-height: 180px;
        z-index: 111;
        text-align: left;
    }

    .the-personal-select__item {
        padding: 7px 10px 7px;
        cursor: pointer;
        font-size: 13px;
        line-height: normal;
        transition: 0.2s ease background-color;
        outline: none;

        &:hover {
            background-color: rgba(23, 71, 158, 0.05);
        }

        @include bp($bp-desktop-sm) {
            &:focus-visible {
                @include outline(-2px);
            }
        }
    }

    .the-personal-select__item--selected {
        background-color: rgba(23, 71, 158, 0.15);
        pointer-events: none;
    }
</style>
