<script>
    export default {
        name: "ThePersonalSkeletonOrder",
    };
</script>

<template>
    <div class="the-personal-skeleton-order">
        <div
            class="the-personal-skeleton-order__item the-personal-skeleton"
            v-for="item in 6"
            :key="item"
        ></div>
    </div>
</template>

<style lang="scss">
    .the-personal-skeleton-order {
        @include bp($bp-desktop-sm) {
            padding-top: 20px;
        }
    }

    .the-personal-skeleton-order__item {
        height: 160px;
        margin-bottom: 10px;
        background-color: #e5e5ec;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            margin: 0 30px 10px;
            height: 40px;
        }
    }
</style>
