<script>
    import { apiUrl } from "@/utils";

    export default {
        name: "ThePersonalOrdersProducts",
        props: {
            products: {
                type: Array,
                required: true,
            },
        },
        methods: {
            apiUrl,
            getDiscountPercent(product) {
                return product.price > 0
                    ? 100 - (product.finalPrice / (product.price / 100)).toFixed(0)
                    : 0;
            },
            productSum(product) {
                return product.finalPrice * product.quantity;
            },
        },
    };
</script>

<template>
    <div class="the-personal-orders-products">
        <header class="the-personal-orders-products__head">
            <div class="the-personal-orders-products__cell">
                <div class="the-personal-orders-products__title">Товар</div>
            </div>
            <div class="the-personal-orders-products__cell">
                <div class="the-personal-orders-products__title">Цена</div>
            </div>
            <div class="the-personal-orders-products__cell">
                <div class="the-personal-orders-products__title">Скидка</div>
            </div>
            <div class="the-personal-orders-products__cell">
                <div class="the-personal-orders-products__title">Количество</div>
            </div>
            <div class="the-personal-orders-products__cell">
                <div class="the-personal-orders-products__title">Сумма</div>
            </div>
        </header>
        <div
            class="the-personal-orders-products__row"
            v-for="product in products"
            :key="product.id"
        >
            <div class="the-personal-orders-products__cell">
                <div class="the-personal-orders-products__link">
                    <div class="the-personal-orders-products__image">
                        <img :src="apiUrl(product.image.small)" alt="" />
                    </div>
                    <div class="the-personal-orders-products__name">
                        {{ product.name }}
                    </div>
                </div>
            </div>
            <div class="the-personal-orders-products__cell">
                <span class="desktop-hidden">Цена</span>
                <div class="the-personal-orders-products__text">
                    {{ product.price | formatPrice }} ₽
                </div>
            </div>
            <div class="the-personal-orders-products__cell">
                <span v-if="getDiscountPercent(product)" class="desktop-hidden">Скидка</span>
                <div
                    v-if="getDiscountPercent(product)"
                    class="the-personal-orders-products__text"
                >
                    <div class="the-personal-orders-products__discount">
                        {{ getDiscountPercent(product) }}%
                    </div>
                </div>
            </div>
            <div class="the-personal-orders-products__cell">
                <span class="desktop-hidden">Количество</span>
                <div class="the-personal-orders-products__text">{{ product.quantity }}</div>
            </div>
            <div class="the-personal-orders-products__cell">
                <span class="desktop-hidden">Сумма</span>
                <div
                    class="the-personal-orders-products__text"
                    :class="{ accent: getDiscountPercent(product) }"
                >
                    {{ productSum(product) | formatPrice }} ₽
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-orders-products {
        margin-bottom: 10px;
        padding: 10px 10px 15px;
        border: 1px solid $personal-divider-fill;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            margin-bottom: 15px;
            padding: 15px 15px 25px;
        }
    }

    .the-personal-orders-products__head {
        display: none;
        @include bp($bp-desktop-sm) {
            display: flex;
            padding-bottom: 15px;
            border-bottom: 1px solid $personal-divider-fill;
        }
    }

    .the-personal-orders-products__title {
        @include bp($bp-desktop-sm) {
            color: $personal-title-color;
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
        }
    }

    .the-personal-orders-products__row {
        padding: 10px 0 2px;
        &:first-of-type {
            padding-top: 0;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $personal-divider-fill;
        }
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            padding: 15px 0;
            &:first-of-type {
                padding-top: 15px;
            }
        }
    }

    .the-personal-orders-products__cell {
        display: flex;
        span {
            color: #555770;
            font-size: 12px;
            font-weight: 300;
            line-height: 20px;
        }
        @include bp($bp-desktop-sm) {
            display: block;
            &:first-child {
                flex-basis: 52%;
                max-width: 52%;
            }
            flex-basis: 12%;
            max-width: 12%;
        }
    }

    .the-personal-orders-products__link {
        display: flex;

        width: 100%;
        margin-bottom: 5px;
        @include bp($bp-desktop-sm) {
            align-items: center;
            margin-bottom: 0;
            padding-right: 10px;
        }
    }

    .the-personal-orders-products__text {
        flex-grow: 1;
        margin-bottom: 8px;
        font-size: 14px;
        text-align: right;
        color: $personal-orders-text-color;
        &.accent {
            color: $personal-danger-color;
        }
        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
        }
    }

    .the-personal-orders-products__image {
        flex-shrink: 0;
        width: 100%;
        max-width: 80px;
        margin-right: 15px;
        @include bp($bp-desktop-sm) {
            margin-right: 60px;
        }
    }

    .the-personal-orders-products__name {
        color: $personal-orders-text-color;
        font-size: 14px;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-personal-orders-products__discount {
        display: inline-block;
        padding: 3px 5px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        background-color: $personal-danger-color;
        border-radius: $personal-orders-label-radius;
    }
</style>
