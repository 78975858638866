<script>
    import { apiUrl } from "@/utils";
    import TransitionContainer from "@/components/TransitionContainer";
    import ThePersonalOrdersDetail from "@/ThePersonal/ThePersonalOrdersDetail";
    import ThePersonalOrdersProducts from "@/ThePersonal/ThePersonalOrdersProducts";
    import ThePersonalIcon from "@/ThePersonal/components/ThePersonalIcon";
    import ThePersonalOrdersPagination from "@/ThePersonal/ThePersonalOrdersPagination";
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";
    import ThePersonalSkeletonOrder from "@/ThePersonal/ThePersonalSkeletonOrder";

    export default {
        name: "ThePersonalOrdersTable",
        components: {
            ThePersonalSkeletonOrder,
            ThePersonalTooltip,
            ThePersonalOrdersPagination,
            ThePersonalIcon,
            ThePersonalOrdersProducts,
            ThePersonalOrdersDetail,
            TransitionContainer,
        },
        props: {
            orders: {
                type: Array,
                required: true,
            },
            ordersType: String,
            totalOrders: Number,
            lastPage: Number,
            isLoadedOrders: Boolean,
        },
        data() {
            return {
                activeIndexes: [],
            };
        },
        computed: {
            emptyOrdersText() {
                const types = {
                    active: "Нет активных заказов",
                    finished: "Нет завершенных заказов",
                    all: "Нет заказов",
                };
                return types[this.ordersType];
            },
            siteNames() {
                return {
                    Madrobots: "Madrobots",
                    "Bivan.ru": "Биван",
                    "Халатенце.рф": "Халатенце",
                    "picooc.ru": "Picooc",
                    "miku-russia.ru": "Miku",
                    "mi-ku.ru": "Miku",
                    "XD Design": "XD Design",
                    Petoneer: "Petoneer",
                    Chipolo: "Chipolo",
                    "Nanoleaf.ru": "Nanoleaf",
                    "Магазин ZAVTRA": "Zavtra",
                    "Travel-blue.ru": "Travel Blue",
                    "Ledgerwalletrussia.ru": "Ledger",
                    "Ugears.ru": "Ugears",
                    "Allscales.ru": "AllScales",
                };
            },
            siteIconsName() {
                return {
                    Madrobots: "madrobots-favicon.png",
                    "Bivan.ru": "bivan-favicon.png",
                    "Халатенце.рф": "halatence-favicon.png",
                    "picooc.ru": "picooc-favicon.png",
                    "miku-russia.ru": "miku-favicon.png",
                    "mi-ku.ru": "miku-favicon.png",
                    "XD Design": "xd-favicon.png",
                    Petoneer: "petoneer-favicon.png",
                    Chipolo: "chipolo-favicon.png",
                    "Nanoleaf.ru": "nanoleaf-favicon.png",
                    "Магазин ZAVTRA": "zavtra-favicon.png",
                    "Travel-blue.ru": "tb-favicon.png",
                    "Ledgerwalletrussia.ru": "ledger-favicon.png",
                    "Ugears.ru": "ugears-favicon.png",
                    "Allscales.ru": "madrobots-favicon.png",
                };
            },
        },
        methods: {
            changePageHandler(page) {
                this.$emit("on-page-change", page);
            },
            toggleDetailOrder(orderId) {
                if (this.activeIndexes.includes(orderId)) {
                    const removedIndex = this.activeIndexes.indexOf(orderId);
                    this.activeIndexes.splice(removedIndex, 1);
                } else {
                    this.activeIndexes.push(orderId);
                }
            },
            getSiteIcon(siteName) {
                return apiUrl(`/include/the_personal/${this.siteIconsName[siteName]}`);
            },
            getSiteName(siteName) {
                return this.siteNames[siteName];
            },
            getOrderStatus(order) {
                if (order.paid && !order.finished) {
                    return "Оплачен";
                }
                if (order.paid && order.finished) {
                    return "Заказ доставлен";
                }
                if (!order.payments.cash && !order.payments.paid && !order.finished) {
                    return "Ожидает оплаты";
                }
                return order.status;
            },
            getOrderTracking(order) {
                const trackingNumber = order.tracking.trackingNumber;
                const companyName = order.tracking.method.company;
                if (!trackingNumber) {
                    return false;
                }
                if (trackingNumber) {
                    if (["Boxberry", "Боксберри"].includes(companyName)) {
                        return `https://boxberry.ru/tracking-page?id=${trackingNumber}`;
                    }
                    if (companyName === "PickPoint") {
                        return `https://pickpoint.ru/monitoring/?shop=ООО «Мэдроботс Стор»&number=${trackingNumber}`;
                    }
                    if (companyName === "СДЭК") {
                        return `https://www.cdek.ru/track.html?order_id=${trackingNumber}`;
                    }
                } else {
                    return "no-tracking";
                }
            },
        },
    };
</script>

<template>
    <div class="the-personal-orders-table">
        <the-personal-skeleton-order v-show="!isLoadedOrders"></the-personal-skeleton-order>
        <template v-if="isLoadedOrders">
            <div class="the-personal-orders-table__top">
                <div class="the-personal-orders-table__cell">
                    <div class="the-personal-orders-table__head">Номер заказа</div>
                </div>
                <div class="the-personal-orders-table__cell">
                    <div class="the-personal-orders-table__head">Дата заказа</div>
                </div>
                <div class="the-personal-orders-table__cell">
                    <div class="the-personal-orders-table__head">Сумма заказа</div>
                </div>
                <div class="the-personal-orders-table__cell"></div>
                <div class="the-personal-orders-table__cell">
                    <div class="the-personal-orders-table__head">Статус заказа</div>
                </div>
            </div>

            <div
                class="the-personal-orders-table__row is-empty-state"
                v-if="isLoadedOrders && !orders.length"
            >
                <div class="the-personal-orders-table__cell">{{ emptyOrdersText }}</div>
            </div>

            <div
                class="the-personal-orders-table__order"
                v-for="order in orders"
                :key="order.id"
            >
                <div
                    class="the-personal-orders-table__row"
                    :class="{ opened: activeIndexes.includes(order.id) }"
                    @click="toggleDetailOrder(order.id)"
                    @keypress.enter="toggleDetailOrder(order.id)"
                    tabindex="0"
                >
                    <the-personal-icon class="arrow-bottom" name="arrow"></the-personal-icon>

                    <a
                        v-if="getOrderTracking(order)"
                        :href="getOrderTracking(order)"
                        class="the-personal-orders-table__track desktop-hidden"
                        target="_blank"
                        @click.stop
                    >
                        Отследить заказ

                        <the-personal-icon name="track"></the-personal-icon>
                        <the-personal-icon class="arrow-top" name="arrow"></the-personal-icon>
                    </a>
                    <div
                        v-if="getOrderTracking(order) === 'no-tracking'"
                        class="the-personal-orders-table__track desktop-hidden disabled"
                    >
                        Этот оператор не отслеживает заказы
                    </div>

                    <div class="the-personal-orders-table__cell desktop-hidden">
                        <span>Сайт</span>
                        <div class="the-personal-orders-table__text">
                            {{ getSiteName(order.site) }}
                        </div>
                    </div>

                    <div class="the-personal-orders-table__cell">
                        <span class="desktop-hidden">Номер</span>
                        <div
                            class="
                                the-personal-orders-table__text
                                the-personal-orders-table__text--icon
                            "
                        >
                            <div class="the-personal-orders-table__site-name">
                                <img
                                    :src="getSiteIcon(order.site)"
                                    :alt="order.site"
                                    :title="order.site"
                                />
                            </div>
                            {{ order.number }}
                        </div>
                    </div>
                    <div class="the-personal-orders-table__cell">
                        <span class="desktop-hidden">Дата</span>
                        <div class="the-personal-orders-table__text">
                            {{ order.date }}
                        </div>
                    </div>
                    <div class="the-personal-orders-table__cell">
                        <span class="desktop-hidden">Сумма</span>
                        <div class="the-personal-orders-table__text">
                            {{ order.totalPrice | formatPrice }} ₽
                        </div>
                    </div>
                    <div class="the-personal-orders-table__cell mobile-hidden">
                        <a
                            v-if="getOrderTracking(order)"
                            :href="getOrderTracking(order)"
                            class="the-personal-orders-table__track"
                            target="_blank"
                            @click.stop
                        >
                            Отследить заказ
                            <the-personal-icon name="track"></the-personal-icon>
                        </a>
                        <div
                            v-if="getOrderTracking(order) === 'no-tracking'"
                            class="the-personal-orders-table__track disabled"
                        >
                            Отследить заказ
                            <div class="the-personal-orders-table__track-icon">
                                <the-personal-icon name="info"></the-personal-icon>
                                <div class="the-personal-orders-table__tooltip">
                                    <the-personal-tooltip width="235" position-x="left">
                                        Этот оператор не отслеживает заказы
                                    </the-personal-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="the-personal-orders-table__cell">
                        <span class="desktop-hidden">Статус</span>
                        <div
                            class="the-personal-orders-table__status"
                            :class="{
                                'the-personal-orders-table__status--paid':
                                    getOrderStatus(order) === 'Оплачен',
                                'the-personal-orders-table__status--waiting':
                                    getOrderStatus(order) === 'Ожидает оплаты',
                            }"
                        >
                            <!--                        {{ order.status }}-->
                            {{ getOrderStatus(order) }}
                        </div>
                    </div>
                    <div class="the-personal-orders-table__link desktop-hidden">
                        {{
                            activeIndexes.includes(order.id)
                                ? "Свернуть"
                                : "Подробнее о заказе"
                        }}
                    </div>
                </div>

                <transition-container :show="activeIndexes.includes(order.id)">
                    <div class="the-personal-orders-table__detail">
                        <the-personal-orders-products
                            v-if="order.products.length"
                            :products="order.products"
                        ></the-personal-orders-products>

                        <the-personal-orders-detail
                            title="Данные получателя"
                            :data="order.buyer"
                        ></the-personal-orders-detail>

                        <the-personal-orders-detail
                            title="Доставка"
                            :data="order.delivery"
                        ></the-personal-orders-detail>

                        <the-personal-orders-detail
                            v-if="order.history.length"
                            title="История обработки заказа"
                            :data="order.history"
                            is-history
                        ></the-personal-orders-detail>
                    </div>
                </transition-container>
            </div>
            <div v-if="totalOrders > 12" class="the-personal-orders-table__pagination">
                <the-personal-orders-pagination
                    :max-pages="lastPage"
                    @on-page-change="changePageHandler"
                ></the-personal-orders-pagination>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    .the-personal-orders-table {
        @include bp($bp-desktop-sm) {
            padding: 10px 0 20px;
            border-radius: $personal-box-radius;
            box-shadow: $personal-common-shadow-max;
            border: $personal-common-border-full;
        }
    }

    .the-personal-orders-table__order {
        position: relative;
        margin-bottom: 10px;
        border-radius: $personal-box-radius;
        box-shadow: $personal-common-shadow-min;
        border: $personal-common-border-full;

        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
            box-shadow: none;
            border: none;
            &:not(:last-child) {
                &:before {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    right: 30px;
                    left: 30px;
                    height: 1px;
                    background: $personal-divider-gradient;
                }
            }
        }
    }

    .the-personal-orders-table__top {
        display: none;
        @include bp($bp-desktop-sm) {
            display: flex;
            padding: 0 30px;
        }
    }

    .the-personal-orders-table__row {
        position: relative;
        padding: 15px;
        outline: none;
        svg.icon-arrow.arrow-bottom {
            position: absolute;
            bottom: 15px;
            right: 15px;
            width: 16px;
            height: 16px;
            fill: $personal-active-color;
            transform: rotate(90deg);
            transition: transform 0.2s ease-in;
        }
        &.opened {
            svg.icon-arrow.arrow-bottom {
                transform: rotate(270deg);
            }
        }
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-wrap: wrap;
            padding: 0 30px;
            transition: background-color 0.2s ease-in;
            svg.icon-arrow.arrow-bottom {
                bottom: auto;
                top: 50%;
                right: 30px;
                transform: translateY(-50%) rotate(90deg);
            }
            &:focus-visible {
                @include outline(-2px);
            }
            &:hover:not(.is-empty-state) {
                background-color: $personal-orders-hover-fill;
                cursor: pointer;
            }
            &.is-empty-state {
                .the-personal-orders-table__cell {
                    max-width: 100%;
                    flex-basis: 100%;
                }
            }
            &.opened {
                svg.icon-arrow.arrow-bottom {
                    transform: translateY(-50%) rotate(270deg);
                }
                .the-personal-orders-table__text {
                    color: $personal-orders-text-color--selected;
                }
            }
        }
    }

    .the-personal-orders-table__cell {
        display: flex;
        margin-bottom: 8px;
        span {
            width: 80px;
            color: #555770;
            font-size: 14px;
            line-height: 22px;
        }
        @include bp($bp-desktop-sm) {
            display: block;
            flex-basis: 20%;
            max-width: 20%;
            margin-bottom: 0;
            padding: 10px 0;
        }
    }

    .the-personal-orders-table__detail {
        padding: 0 10px 5px;
        @include bp($bp-desktop-sm) {
            padding: 15px;
        }
    }

    .the-personal-orders-table__head {
        @include bp($bp-desktop-sm) {
            color: $personal-title-color;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .the-personal-orders-table__text {
        position: relative;
        flex-grow: 1;
        color: $personal-orders-text-color;
        font-size: 16px;
        font-weight: $personal-orders-text-weight;
        line-height: 20px;
        text-align: right;
        transition: color 0.2s ease-in;
        @include bp($bp-desktop-sm) {
            text-align: left;
        }
    }

    .the-personal-orders-table__text--icon {
        @include bp($bp-desktop-sm) {
            padding-left: 30px;
        }
    }

    .the-personal-orders-table__site-name {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 20px;
            height: 20px;
            transform: translateY(-50%);
        }
    }

    .the-personal-orders-table__track {
        position: relative;
        display: block;
        margin-bottom: 10px;
        padding-bottom: 10px;
        color: $personal-orders-active-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        border-bottom: 1px solid $personal-divider-fill;
        svg.icon-track,
        svg.icon-info {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }
        svg.icon-track {
            fill: currentColor;
        }
        svg.icon-arrow.arrow-top {
            position: absolute;
            top: 0;
            right: 0;
            width: 12px;
            height: 12px;
            fill: currentColor;
        }
        &.disabled {
            color: #8f90a6;
        }
        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
            &.disabled {
                display: flex;
            }
        }
    }

    .the-personal-orders-table__track-icon {
        display: none;
        @include bp($bp-desktop-sm) {
            position: relative;
            display: block;
            margin-left: 2px;
            &:hover {
                .the-personal-orders-table__tooltip {
                    display: block;
                }
            }
        }
    }

    .the-personal-orders-table__tooltip {
        display: none;
    }

    .the-personal-orders-table__link {
        color: $personal-active-color;
        font-size: 14px;
        font-weight: 500;
    }

    .the-personal-orders-table__status {
        flex-grow: 1;
        text-align: right;
        @include bp($bp-desktop-sm) {
            padding-right: 25px;
            color: $personal-title-color;
            font-size: 16px;
            font-weight: $personal-orders-text-weight;
            line-height: 20px;
            text-align: left;
        }
    }

    .the-personal-orders-table__status--paid {
        color: #52c41a;
    }

    .the-personal-orders-table__status--waiting {
        color: $personal-orders-danger-color;
    }

    .the-personal-orders-table__pagination {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        @include bp($bp-desktop-sm) {
            padding: 20px 30px 0;
        }
    }
</style>
