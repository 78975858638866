<script>
    import ThePersonalOrdersTable from "@/ThePersonal/ThePersonalOrdersTable";
    import ThePersonalSelect from "@/ThePersonal/components/ui/ThePersonalSelect";

    export default {
        name: "ThePersonalOrders",
        components: { ThePersonalSelect, ThePersonalOrdersTable },
        data() {
            return {
                orders: [],
                totalOrders: null,
                lastPage: null,
                currentType: this.$route.query.type || "active",
                isLoadedOrders: false,
            };
        },
        computed: {
            ordersType() {
                return [
                    { name: "Активные заказы", value: "active" },
                    { name: "Завершенные заказы", value: "finished" },
                    { name: "Все заказы", value: "all" },
                ];
            },
            initialOrderType() {
                return this.ordersType.find((type) => type.value === this.currentType).name;
            },
        },
        mounted() {
            this.getProfileOrders(this.$route.query.page || 1);
        },
        methods: {
            selectOrdersTypeHandler({ option }) {
                this.currentType = option.value;
                this.isLoadedOrders = false;
                this.getProfileOrders();
                this.$router.push({ query: { page: 1, type: this.currentType } });
            },
            changePageHandler(page) {
                this.$store.profileApi
                    .getProfileOrders(page, this.currentType)
                    .then(({ orders }) => (this.orders = orders));
            },
            getProfileOrders(page = 1) {
                this.$store.profileApi
                    .getProfileOrders(page, this.currentType)
                    .then(({ orders, totalOrders, lastPage }) => {
                        this.orders = orders;
                        this.totalOrders = totalOrders;
                        this.lastPage = lastPage;
                        this.isLoadedOrders = true;
                    });
            },
        },
    };
</script>

<template>
    <section class="the-personal-orders">
        <div class="the-personal-orders__select">
            <the-personal-select
                :options="ordersType"
                type="orders"
                :value="initialOrderType"
                size="sm"
                @input="selectOrdersTypeHandler"
            ></the-personal-select>
        </div>
        <the-personal-orders-table
            :orders="orders"
            :total-orders="totalOrders"
            :orders-type="currentType"
            :last-page="lastPage"
            :is-loaded-orders="isLoadedOrders"
            @on-page-change="changePageHandler"
        ></the-personal-orders-table>
    </section>
</template>

<style lang="scss">
    .the-personal-orders__select {
        margin-bottom: 10px;
        @include bp($bp-desktop-sm) {
            max-width: 240px;
            margin-bottom: 15px;
        }
    }
</style>
