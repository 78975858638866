<script>
    import ThePersonalIcon from "@/ThePersonal/components/ThePersonalIcon";

    export default {
        name: "ThePersonalOrdersPagination",
        components: { ThePersonalIcon },
        props: {
            maxPages: Number,
        },
        data() {
            return {
                currentPage: 1,
            };
        },
        mounted() {
            this.currentPage = Number(this.$route.query.page) || 1;
        },
        methods: {
            changePage(number, replace = false) {
                if (replace) {
                    this.currentPage = number;
                } else {
                    this.currentPage += number;
                }
                if (this.currentPage < 1) this.currentPage = 1;
                if (this.currentPage > this.maxPages) this.currentPage = this.maxPages;
                if (
                    this.currentPage > 0 &&
                    this.currentPage <= this.maxPages &&
                    this.currentPage !== Number(this.$route.query.page)
                ) {
                    const routeQueries = this.$route.query;
                    this.$router.push({ query: { ...routeQueries, page: this.currentPage } });
                    this.emitChange();
                }
            },
            keyPressHandler({ target }) {
                this.changePage(Number(target.value), true);
            },
            emitChange() {
                this.$emit("on-page-change", this.currentPage);
            },
        },
    };
</script>

<template>
    <div class="the-personal-orders-pagination">
        <div class="the-personal-orders-pagination__count">
            <input
                class="the-personal-orders-pagination__input"
                type="number"
                min="1"
                :max="maxPages"
                :value="currentPage"
                @keypress.enter="keyPressHandler"
            />
            / {{ maxPages }}
        </div>
        <div class="the-personal-orders-pagination__controls">
            <button
                class="the-personal-orders-pagination__arrow prev outline"
                :disabled="currentPage === 1"
                @click="changePage(-1)"
            >
                <the-personal-icon name="arrow"></the-personal-icon>
            </button>
            <button
                class="the-personal-orders-pagination__arrow next outline"
                :disabled="currentPage === maxPages"
                @click="changePage(1)"
            >
                <the-personal-icon name="arrow"></the-personal-icon>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-orders-pagination {
        display: flex;
        align-items: center;
        @include bp($bp-desktop-sm) {
            padding: 10px 0;
        }
    }

    .the-personal-orders-pagination__count {
        margin-right: 30px;
        color: #555770;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
    }

    .the-personal-orders-pagination__input {
        width: 26px;
        height: 26px;
        color: $personal-active-color;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        border: $personal-border-width solid $personal-input-border-color;
        border-radius: $personal-select-radius-sm;
        -moz-appearance: textfield !important;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .the-personal-orders-pagination__controls {
        display: inline-flex;
    }

    .the-personal-orders-pagination__arrow {
        position: relative;
        width: 28px;
        height: 28px;
        background-color: $personal-button-fill;
        transition: background-color 0.2s ease-in;
        border-radius: $personal-select-radius-sm;
        @include bp($bp-desktop-sm) {
            width: 26px;
            height: 26px;
            &:hover {
                background-color: $personal-button-fill--hover;
            }
        }

        &:disabled {
            background-color: #e4e4eb;
        }
        &.prev {
            margin-right: 25px;
            svg {
                transform: rotate(180deg);
            }
            @include bp($bp-desktop-sm) {
                margin-right: 0;
                border-radius: $personal-select-radius-sm 0 0 $personal-select-radius-sm;
            }
        }
        &.next {
            @include bp($bp-desktop-sm) {
                border-radius: 0 $personal-select-radius-sm $personal-select-radius-sm 0;
            }
        }
        svg {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 12px;
            height: 12px;
            fill: #fff;
            @include bp($bp-desktop-sm) {
                top: 7px;
                left: 7px;
            }
        }
    }
</style>
