<script>
    export default {
        name: "ThePersonalOrdersDetail",
        props: {
            title: {
                type: String,
                required: true,
            },
            data: {
                type: [Object, Array],
                required: true,
            },
            isHistory: Boolean,
        },
        methods: {
            getFieldName(name) {
                const types = {
                    name: "ФИО",
                    phone: "Телефон",
                    email: "Почта",
                    address: "Адрес",
                    type: "Тип доставки",
                    price: "Сумма",
                    payment: "Способ оплаты",
                };
                return types[name] || name;
            },
        },
    };
</script>

<template>
    <div class="the-personal-orders-detail">
        <div class="the-personal-orders-detail__head">
            <div class="the-personal-orders-detail__title">{{ title }}</div>
        </div>
        <div
            class="the-personal-orders-detail__inner"
            :class="{ 'the-personal-orders-detail__inner--block': isHistory }"
        >
            <template v-if="Array.isArray(data)">
                <div
                    class="the-personal-orders-detail__item"
                    v-for="(item, index) in data"
                    :key="index"
                >
                    <div class="the-personal-orders-detail__key">
                        {{ item.date }}
                    </div>
                    <div class="the-personal-orders-detail__value">
                        {{ item.status }}
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    class="the-personal-orders-detail__item"
                    v-for="(value, key) in data"
                    :key="key"
                >
                    <div class="the-personal-orders-detail__key">
                        {{ getFieldName(key) }}{{ isHistory ? "" : ":" }}
                    </div>
                    <div class="the-personal-orders-detail__value">
                        {{ value }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-orders-detail {
        margin-bottom: 10px;
        padding: 10px 10px 5px;
        border: 1px solid $personal-divider-fill;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            margin-bottom: 15px;
            padding: 15px 15px 15px;
        }
    }

    .the-personal-orders-detail__head {
        padding-bottom: 10px;
        border-bottom: 1px solid $personal-divider-fill;
        @include bp($bp-desktop-sm) {
            padding-bottom: 15px;
        }
    }

    .the-personal-orders-detail__title {
        color: $personal-title-color;
        font-size: 14px;
        font-weight: $personal-orders-title-weight;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            line-height: 22px;
        }
    }

    .the-personal-orders-detail__inner {
        padding-top: 10px;
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-wrap: wrap;
            padding-top: 16px;
        }
    }

    .the-personal-orders-detail__inner--block {
        .the-personal-orders-detail__key {
            max-width: 120px;
        }
        @include bp($bp-desktop-sm) {
            display: block;
            .the-personal-orders-detail__item {
                &:nth-child(even),
                &:nth-child(odd) {
                    max-width: 100%;
                    .the-personal-orders-detail__key {
                        max-width: 160px;
                    }
                }
            }
        }
    }

    .the-personal-orders-detail__item {
        display: flex;
        color: $personal-orders-text-color;
        margin-bottom: 8px;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
            &:nth-child(even) {
                flex-basis: 48%;
                max-width: 48%;
                .the-personal-orders-detail__key {
                    max-width: 100px;
                }
            }
            &:nth-child(odd) {
                flex-basis: 52%;
                max-width: 52%;
                .the-personal-orders-detail__key {
                    max-width: 160px;
                }
            }
        }
    }

    .the-personal-orders-detail__key {
        width: 100%;
        max-width: 80px;
        font-size: 12px;
        font-weight: $personal-orders-detail-key-weight;
        line-height: 20px;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 18px;
        }
    }

    .the-personal-orders-detail__value {
        font-weight: $personal-orders-detail-value-weight;
        flex-grow: 1;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
            text-align: left;
        }
    }
</style>
